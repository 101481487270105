<template lang="pug">
    div
        template(v-if="userLanguage == 'fr'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 Qu’est-ce qu’un article ?
                p.content(v-if="toggleQuestions[0] === true") 
                    | Tous les utilisateurs de la plateforme peuvent publier un article. 
                    br
                    | Un article peut être associé à des tags : chapitres et compétences. Ainsi les utilisateurs possédant l’un de ces tags seront notifiés lors de la publication de l’article.
                    br
                    br
                    | Un article a deux états : 
                    ul 
                        li - Publié
                        li - Brouillon
                    br
                    | Un article publié est accessible par tous les utilisateurs de la plateforme via le moteur de recherche.
                    br
                    | Un article à l’état de brouillon n’est visible que par son auteur et n’est pas accessible via le moteur de recherche. Un brouillon peut être publié à tout moment, et devenir un article. 
                    br 
                    | Les modérateurs, administrateurs de la plateforme et l’auteur d’un article peuvent le modifier. Cette action entrainera la création d’une nouvelle version, chaque article possède un historique de ses versions. 

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 Comment créer un article ?
                p.content(v-if="toggleQuestions[1] === true") 
                    | Cliquez sur le bouton “Créer” en haut à droite de n’importe quelle page de la plateforme. 
                    br
                    | Cliquez sur « Article ». 
                    br
                    | Renseignez le titre de l’article. 
                    br
                    | Taggez les compétences et /ou les chapitres liés à l’article à l’aide de la barre de recherche de chapitres et de compétences.
                    br
                    | Remplir le contenu de l’article et le mettre en forme grâce aux outils d’édition. 
                    br
                    | Utilisez la barre d’options afin d’insérer des liens, vidéos et images dans l’article.
                    br 
                    | Insérez des pièces-jointes à l'article si nécessaire.  
                    br 
                    | Cliquez sur le bouton « Enregistrer » pour l’enregistrer en tant que brouillon ou bien cliquez sur le bouton « Publier » afin de publier l’article sur la plateforme. Il est nécessaire d’avoir ajouté du contenu dans l’article ou une pièce jointe afin de pouvoir cliquer sur les boutons « Publier » et « Enregistrer ». 

        template(v-if="userLanguage == 'zh-cn'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 文章是什么？
                p.content(v-if="toggleQuestions[0] === true") 
                    | 平台的所有用户都可以发布文章。
                    br
                    | 一篇文章可以与标签相关联：小组和技能。因此，当文章发布时，拥有一个或多个这些标签的用户会收到通知。
                    br
                    | 文章有两种状态：
                    br
                    ul 
                        li -已发表
                        li -草稿
                    br
                    | 已发表的文章可由平台的所有用户通过搜索引擎访问。
                    br
                    | 草稿只有作者可见，搜索引擎的结果不会显示文章草稿。
                    br 
                    | 内容审核员，作者和管理员可以修改文章。修改会创建一个新的版本，每篇文章都有以前版本的历史版本。

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 如何发布文章？
                p.content(v-if="toggleQuestions[1] === true") 
                    | 1. 点击平台上任意页面右上方的 "创建 "按钮。
                    br
                    | 2. 点击 "文章"。
                    br
                    | 3. 填写文章的标题。
                    br
                    | 4. 使用搜索栏添加相关的小组和技能。
                    br
                    | 5. 填写文章内容，编辑格式。
                    br
                    | 6. 使用上方的工具栏在文章中插入文本框、链接、视频和图片。
                    br 
                    | 7. 可以点击右侧加号在文章中插入附件或连接平台中的相关文章。
                    br 
                    | 8. 点击 "保存 "按钮将其保存为草稿，或者点击 "发布 "按钮将文章发布到平台上。必须要有内容或附件才能点击 "发布 "和 "保存 "按钮。

        template(v-if="userLanguage == 'en'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 What is an article?
                p.content(v-if="toggleQuestions[0] === true")
                    | All users of the Skillbase platform can publish an article.
                    br
                    | An article can be associated with tags: chapters and skills. Thus users with one of these tags will be notified when the article is published.
                    br
                    | The articles have two states:
                    br
                    ul
                        li - Published
                        li - Draft
                    br
                    | A published article is accessible by all users of the platform via the search engine.
                    br
                    | A draft article is only visible by its author and is not accessible via the search engine.
                    br
                    | Moderator, author and the administrator can modify the article. Modification will create a new version, each article has a history of previous versions.

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 How to create an article?
                p.content(v-if="toggleQuestions[1] === true")
                    | Click the "Create" button at the top right of any page on the platform.
                    br
                    | Click "Article".
                    br
                    | Fill in the title of the article.
                    br
                    | Use the chapter and skill search bar to tag skills and chapters related to the article.
                    br
                    | Fill in the content of the article and use the text editor tools to edit format.
                    br
                    | Use the option bar to insert links, videos and images in the article.
                    br
                    | Insert attachments to the article if needed.
                    br
                    | Click on the "Save" button to save it as a draft or click on the "Publish" button to publish the article on the platform. It is necessary to have content or an attachment in order to click on the "Publish" and "Save" buttons.
</template>


<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            toggleQuestions: []
        }
    },

    computed: {
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    }
})
</script>

<style lang="scss" scoped>
@import './question.scss';

</style>